<script lang="ts" setup>
import { TextStyle, TextStyleFontSize } from '@app/customer/Components';
import {
  BuildingOfficeIcon,
  BuildingStorefrontIcon,
  HomeIcon,
  PrinterIcon,
  QrCodeIcon,
} from '@heroicons/vue/24/outline';
import { CheckCircleIcon } from '@heroicons/vue/24/solid';
import { computed } from 'vue';
import { type Types, App } from '@app/shared/types/generated-v2';

type ShippingMethodOptionViewModel = Types['App.Http.ViewModels.Customer.ShippingMethodOptionViewModel'];

const props = defineProps<{
  shippingMethodOption: ShippingMethodOptionViewModel;
  checked: boolean;
}>();

const emit = defineEmits<{
  select: [formShippingMethod: ShippingMethodOptionViewModel];
}>();

function selectFormShippingMethod() {
  emit('select', props.shippingMethodOption);
}

const borderClassList = computed(() => {
  return [
    { 'border-blue-500 bg-blue-50': props.checked },
    { 'hover:border-slate-400': !props.checked },
  ];
});
</script>

<template>
  <div
    tabindex="0"
    class="border-slate-300 border rounded-lg shadow-sm p-4 space-y-4 text-sm cursor-pointer outline-blue-500"
    :class="borderClassList"
    @click="selectFormShippingMethod"
    @keyup.enter="selectFormShippingMethod"
  >
    <div class="flex items-center w-full font-medium text-sm">
      <span class="truncate inline-block">
        {{ shippingMethodOption.name }}
      </span>
      <span
        v-if="shippingMethodOption.price"
        class="mx-2 text-slate-500"
      >·</span>
      <span class="flex-shrink-0">{{ shippingMethodOption.price }}</span>

      <div
        v-if="checked"
        class="ml-auto"
      >
        <CheckCircleIcon class="h-5 w-5 fill-blue-500 ml-2" />
      </div>
    </div>

    <div class="flex space-x-4">
      <img
        v-if="shippingMethodOption.imageSrc"
        class="h-10 w-10"
        :src="shippingMethodOption.imageSrc"
        loading="lazy"
      >
      <div>
        <div>
          {{ shippingMethodOption.carrierName }}

          <span
            v-if="shippingMethodOption.distance"
            class="text-slate-500 text-xs"
          >
            ({{ shippingMethodOption.distance }})
          </span>
        </div>

        <TextStyle
          v-if="shippingMethodOption.type === App.Enums.ShippingMethodType.DropOff"
          :font-size="TextStyleFontSize.Small"
        >
          <div class="flex space-x-1 items-center mt-2">
            <BuildingOfficeIcon class="h-5 w-5" />

            <span>
              {{ $t('customer.shipping:drop-off') }}
            </span>
          </div>
        </TextStyle>

        <TextStyle
          v-if="shippingMethodOption.type === App.Enums.ShippingMethodType.PickUp"
          :font-size="TextStyleFontSize.Small"
        >
          <div class="flex space-x-1 items-center mt-2">
            <HomeIcon class="h-5 w-5" />

            <span>
              {{ $t('customer.shipping:pick-up') }}
            </span>
          </div>
        </TextStyle>

        <TextStyle
          v-if="shippingMethodOption.type === App.Enums.ShippingMethodType.ReturnInStore"
          :font-size="TextStyleFontSize.Small"
        >
          <div class="flex space-x-1 items-center mt-2">
            <BuildingStorefrontIcon class="h-5 w-5" />

            <span>
              {{ shippingMethodOption.returnLocation.completeAddress }}
            </span>
          </div>
        </TextStyle>

        <TextStyle
          v-if="shippingMethodOption.isPrinterless"
          :font-size="TextStyleFontSize.Small"
        >
          <div class="flex items-center space-x-1 mt-1 text-green-600">
            <QrCodeIcon class="h-5 w-5" />

            <span>{{ $t('customer.shipping:printerless') }}</span>
          </div>
        </TextStyle>

        <TextStyle
          v-if="!shippingMethodOption.isPrinterless && shippingMethodOption.type !== 'other'"
          :font-size="TextStyleFontSize.Small"
        >
          <div class="flex items-center space-x-1 mt-1">
            <PrinterIcon class="h-5 w-5" />

            <span>{{ $t('customer.shipping:print-a-label') }}</span>
          </div>
        </TextStyle>
      </div>
    </div>
  </div>
</template>
