<script lang="ts" setup>
import {
  computed,
  onMounted,
  onUnmounted,
  ref,
  useSlots,
} from 'vue';
import { Overlay } from '../../Overlay';
import { XMarkIcon } from '@heroicons/vue/24/solid';

defineProps({
  icon: {
    type: String,
    default: null,
  },

  open: {
    type: Boolean,
    default: false,
  },

  title: {
    type: String,
    default: null,
  },
});

const emit = defineEmits<{ (e: 'close') }>();

function close(): void {
  emit('close');
}

const modal = ref<HTMLElement | null>(null);

function closeModalOnEsc(e): void {
  if (e.key === 'Escape') {
    close();
  }
}

onMounted(() => {
  document.addEventListener('keydown', closeModalOnEsc);

  if (modal.value) {
    modal.value.focus();
  }
});

onUnmounted(() => document.removeEventListener('keydown', closeModalOnEsc));

const slots = useSlots();

const hasFooter = computed<boolean>(() => !!slots['footer']);

const container = ref();

function clickedOutside(event) {
  // The modal needs to closable and the container to click outside of needs to be there
  if (!container.value) {
    return;
  }

  // If the click is outside the container, close the container
  if (!(container.value == event.target || container.value.contains(event.target))) {
    close();
  }
}
</script>

<template>
  <Teleport to="body">
    <Transition>
      <div
        v-if="open"
        ref="modal"
        tabindex="0"
      >
        <Overlay @click="clickedOutside">
          <div
            ref="container"
            data-test="modal"
            class="relative w-full p-4 max-w-xl rounded-xl overflow-hidden shadow-xl bg-white "
          >
            <XMarkIcon
              class="absolute top-0 right-0 z-10 mr-3 mt-3 h-5 w-5 cursor-pointer fill-slate-500 hover:fill-slate-700"
              @click="close"
            />

            <div class="p-4">
              <div
                v-if="icon || title"
                class="flex flex-col items-center space-y-5"
              >
                <img
                  v-if="icon"
                  :src="icon"
                  class="w-14 object-contain"
                  loading="lazy"
                >

                <h3
                  v-if="title"
                  class="text-lg leading-6 font-medium text-slate-900"
                >
                  {{ title }}
                </h3>
              </div>

              <slot />
            </div>

            <div
              v-if="hasFooter"
              class="p-2"
            >
              <slot name="footer" />
            </div>
          </div>
        </Overlay>
      </div>
    </Transition>
  </Teleport>
</template>

<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.1s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
