<script lang="ts" setup>
import { PropType, inject, onMounted, ref } from 'vue';
import {
  Badge,
  Breadcrumbs,
  BreadcrumbsItem,
  HelpText,
  NumberInput,
  TextContainer,
  TextContainerSpacing,
  TextStyle,
  TextStyleFontSize,
} from '@app/customer/Components';
import { CheckIcon, TrashIcon } from '@heroicons/vue/24/outline';
import { type Types, App } from '@app/shared/types/generated-v2';
import { productDetailsService } from '@app/customer/Services';
import { useI18n } from 'vue-i18n';
import { Modal, TextStyleShade } from '@app/customer/Components';
import Heading from '@app/panel/Components/Heading/components/Heading.vue';

type SalesOrderItemViewModel = Types['App.Http.ViewModels.Customer.SalesOrderItemViewModel'];
type ReturnInstructionLocale = Types['App.Http.ViewModels.Customer.ReturnInstructionLocale'];
type ProductDetailType = App.Enums.ProductDetailType;

const { t } = useI18n();

const props = defineProps({
  item: {
    type: Object as PropType<SalesOrderItemViewModel>,
    required: true,
  },

  quantity: {
    type: Number,
    required: true,
  },

  value: {
    type: Number,
    default: 0,
  },

  isVirtualIntegration: {
    type: Boolean,
    default: false,
  },

  mayRemoveItem: {
    type: Boolean,
    default: false,
  },
});

const visibleProductDetails = inject<ProductDetailType[]>('visibleProductDetails');

const { isVisible } = productDetailsService(visibleProductDetails);

const emit = defineEmits(['increment', 'decrement', 'remove']);

const returnQuantity = ref(props.value);

function onOrderItemIncrement(value: number) {
  returnQuantity.value = value;
  emit('increment', value);
}

function onOrderItemDecrement(value: number) {
  returnQuantity.value = value;
  emit('decrement', value);
}

function removeOrderItem() {
  if (confirm(t('customer.order-item:confirm-remove-item'))) {
    emit('remove', props.item);
  }
}

function evaluateReturnQuantity(): void {
  if (!props.isVirtualIntegration) {
    return;
  }

  if (props.value !== 0) {
    return;
  }

  onOrderItemIncrement(1);
}

const shownReason = ref<ReturnInstructionLocale | null>(null);

function showReason(reason: ReturnInstructionLocale | null): void {
  if (reason) {
    shownReason.value = reason;
  }
}
onMounted(() => evaluateReturnQuantity());
</script>

<template>
  <div class="grid grid-cols-10 gap-5 content-start pt-8 pb-8">
    <div
      v-if="!isVirtualIntegration"
      class="col-span-10 sm:col-span-3 bg-white self-start"
    >
      <img
        v-if="item.product.imageSource"
        v-fallback-img
        class="flex-none rounded-md object-contain w-40 sm:max-h-40 sm:w-40 sm:mb-0 mx-auto"
        :src="item.product.imageSource"
        :alt="item.product.name"
        loading="lazy"
      >
    </div>

    <div
      class="col-span-10 self-start"
      :class="{'sm:col-span-7': !isVirtualIntegration}"
    >
      <div
        :class="[
          !isVirtualIntegration
            ? 'flex flex-col sm:flex-row space-y-4 sm:space-y-0'
            : 'grid grid-cols-12 sm:gap-12'
        ]"
      >
        <div
          v-if="!isVirtualIntegration"
          class="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-8"
        >
          <TextContainer :spacing="TextContainerSpacing.Tight">
            <div class="flex flex-col">
              <TextStyle
                :font-size="TextStyleFontSize.Base"
                :color="App.Enums.ColorPalette.Slate"
                strong
              >
                {{ item.productName }}
              </TextStyle>

              <div
                v-if="item.quantity === 0"
                class="mt-1 py-1"
              >
                <Badge color="blue">
                  {{ $t('customer.order-item:cannot-be-returned') }}
                </Badge>
              </div>

              <div
                v-if="item.quantity > 0 && item.returnedQuantity > 0"
                class="mt-1 py-1"
              >
                <Badge color="blue">
                  {{ $t('customer.order-item:already-returned', { 'amount': item.returnedQuantity }) }}
                </Badge>
              </div>
            </div>

            <div
              v-if="item.isReturnable === false"
              class="flex items-center space-x-2"
            >
              <Badge
                color="red"
                :class="item.returnRuleReturnInstruction ? 'cursor-pointer hover:bg-red-100' : ''"
                @click="showReason(item.returnRuleReturnInstruction)"
              >
                {{ $t('customer.order-item:cannot-be-returned') }}
              </Badge>
            </div>

            <TextContainer
              :spacing="TextContainerSpacing.Minimal"
            >
              <div class="flex flex-wrap items-center gap-2 text-sm text-slate-500">
                <template
                  v-if="item.product.productType === App.Enums.ProductType.BUNDLE"
                >
                  <Badge color="green">
                    {{ $t('customer.order-item:bundle') }}
                  </badge>
                </template>

                <template
                  v-if="item.product.parent !== null && item.product.parent.productType === App.Enums.ProductType.BUNDLE"
                >
                  <Badge color="green">
                    {{ $t('customer.order-item:bundle-of', { parent: item.product.parent.name }) }}
                  </badge>
                </template>

                <template
                  v-for="(attribute, index) in item.extensionAttributes"
                  :key="index"
                >
                  <Badge color="slate">
                    <span>{{ attribute.option }}: </span>
                    <span>{{ attribute.value }}</span>
                  </Badge>
                </template>
              </div>
            </TextContainer>

            <Breadcrumbs>
              <Breadcrumbs>
                <BreadcrumbsItem
                  v-if="isVisible(App.Enums.ProductDetailType.SKU)"
                  :name="$t('customer.status:return-item:sku')"
                  :value="item.product.sku"
                />
                <BreadcrumbsItem
                  v-if="item.product.barcode && isVisible(App.Enums.ProductDetailType.BARCODE)"
                  :name="$t('customer.status:return-item:barcode')"
                  :value="item.product.barcode"
                />
                <BreadcrumbsItem
                  v-if="isVisible(App.Enums.ProductDetailType.PRODUCT_ID)"
                  :name="$t('customer.status:return-item:product-id')"
                  :value="item.product.externalId"
                />
                <BreadcrumbsItem
                  v-if="item.product.brand && isVisible(App.Enums.ProductDetailType.BRAND)"
                  :name="$t('customer.status:return-item:brand')"
                  :value="item.product.brand"
                />
              </Breadcrumbs>

              <Breadcrumbs>
                <BreadcrumbsItem v-if="isVisible(App.Enums.ProductDetailType.PRICE) || isVisible(App.Enums.ProductDetailType.DISCOUNT)">
                  <div class="space-x-1">
                    <span class="font-semibold">{{ $t('customer.status:return-item:price') }}:</span>
                    <span v-if="isVisible(App.Enums.ProductDetailType.PRICE)">{{ item.price.label }}</span>
                    <Badge
                      v-if="item.discount.amount && isVisible(App.Enums.ProductDetailType.DISCOUNT)"
                      color="green"
                    >
                      {{ $t('customer.order-item:includes-discount', { discount: item.discount.label }) }}
                    </Badge>
                  </div>
                </BreadcrumbsItem>
              </Breadcrumbs>
            </Breadcrumbs>
          </TextContainer>
        </div>

        <div
          v-if="isVirtualIntegration"
          class="w-full mr-24 col-span-12 sm:col-span-8"
        >
          <slot />
        </div>

        <div
          :class="[
            !isVirtualIntegration
              ? 'flex flex-col flex-shrink-0 items-center sm:items-end space-y-4 sm:ml-auto sm:my-0'
              : 'flex justify-center sm:justify-end col-span-12 sm:col-span-4'
          ]"
        >
          <div
            v-if="isVirtualIntegration && mayRemoveItem"
            class="mt-6 p-2.5 mr-2 text-slate-500 hover:text-slate-700 cursor-pointer"
            @click="removeOrderItem()"
          >
            <TrashIcon
              class=" h-5 w-5"
            />
          </div>

          <div
            v-if="!isVirtualIntegration && item.quantity > 0"
            class="flex space-x-1"
          >
            <CheckIcon class="h-5 w-5 text-green-500" />

            <TextStyle>
              {{ $t('customer.order-item:ordered-quantity', { quantity }) }}
            </TextStyle>
          </div>

          <div
            v-if="item.isReturnable && item.quantity > 0"
            class="text-center space-y-1"
            :class="{'mt-6': isVirtualIntegration}"
          >
            <NumberInput
              :value="returnQuantity"
              :min="!isVirtualIntegration ? 0 : 1"
              :max="!isVirtualIntegration ? item.returnableQuantity : 999"
              @increment="onOrderItemIncrement"
              @decrement="onOrderItemDecrement"
            />

            <HelpText>
              {{ $t('customer.order-item:return-quantity') }}
            </HelpText>
          </div>
        </div>
      </div>

      <div
        v-if="!isVirtualIntegration && value > 0"
        class="sm:col-start-4 col-span-10 sm:col-span-7 mt-4"
      >
        <slot />
      </div>
    </div>
  </div>

  <Modal
    :open="shownReason !== null"
    @close="shownReason = null"
  >
    <div class="space-y-4">
      <Heading>{{ shownReason.title }}</Heading>
      <TextContainer :spacing="TextContainerSpacing.Loose">
        <TextStyle :shade="TextStyleShade.Subdued">
          <span v-html="shownReason?.renderedContent" />
        </TextStyle>
      </TextContainer>
    </div>
  </Modal>
</template>
