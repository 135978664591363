<script lang="ts" setup>
import {
  computed,
} from 'vue';
import { Button } from '../../Button';
import {
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/vue/24/solid';
import { TextStyle } from '../../TextStyle';

const props = defineProps({
  value: {
    type: Number,
    default: null,
  },

  min: {
    type: Number,
    default: 0,
  },

  max: {
    type: [Number, null],
    default: null,
  },

  disabled: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits<{
  (e: 'decrement', value: number): void;
  (e: 'increment', value: number): void;
}>();

function onNumberInputIncrement() {
  let emitValue: number;

  if (props.value === null) {
    emitValue = 0;
  } else {
    emitValue = props.value + 1;
  }

  if (props.max === null || emitValue <= props.max) {
    emit('increment', emitValue);
  }
}

function onNumberInputDecrement() {
  let emitValue: number;

  if (props.value === null) {
    emitValue = 0;
  } else {
    emitValue = props.value - 1;
  }

  if (emitValue >= props.min) {
    emit('decrement', emitValue);
  }
}

const canDecrement = computed(() => props.value > props.min);
const canIncrement = computed(() => props.value < props.max);
</script>

<template>
  <div
    class="flex"
  >
    <Button
      plain
      :disabled="disabled || !canDecrement"
      @click="onNumberInputDecrement"
    >
      <div class="p-2 rounded-l-md border border-r-0 border-slate-300 hover:bg-slate-50 bg-white text-slate-500">
        <ChevronLeftIcon class="h-5 w-5" />
      </div>
    </Button>

    <div class="bg-slate-50 border-slate-300 text-slate-600 inline-flex items-center px-4 py-2 border">
      <TextStyle strong>
        {{ value === null ? '...' : value }}
      </TextStyle>
    </div>

    <Button
      plain
      :disabled="disabled || !canIncrement"
      @click="onNumberInputIncrement"
    >
      <div class="p-2 rounded-r-md border border-l-0 border-slate-300 hover:bg-slate-50 bg-white text-slate-500">
        <ChevronRightIcon class="h-5 w-5" />
      </div>
    </Button>
  </div>
</template>
