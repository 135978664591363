<script lang="ts" setup>
import { useForm, usePage } from '@inertiajs/vue3';
import {
  Banner,
  BannerVariant,
  Button,
  ButtonGroup,
  Checkbox,
  FileUpload,
  FormLayout,
  Heading,
  HelpText,
  InputLabel,
  Link,
  MultiSelect,
  RadioButton,
  Select,
  Textarea,
  TextInput,
  TextStyle,
  TextStyleShade,
} from '@app/customer/Components';
import {
  ReturnQuestionAnswer,
  ReturnReason,
  SalesOrder,
  SalesOrderItem,
} from '@app/customer/types';
import { debounce, forOwn, map } from 'lodash';
import { evaluateReturnQuestions } from '@app/shared/lib/QuestionEngine';
import { AppLayout } from '@app/customer/Layouts';
import { OrderGroup, OrderItem } from '@app/customer/Partials';
import { SelectOption } from '@app/customer/Components';
import { isEmpty, pick, keys } from 'lodash';
import { type Types, App } from '@app/shared/types/generated-v2';
import { useBrandColor } from '@app/customer/Composables/useBrandColor';
import { computed, provide, ref, watch } from 'vue';
import { useUpload } from '../../Composables/useUpload';
import { useFileManager } from '../../Composables/useFileManager';
import { UploadedFileInterface } from '../../Composables/useFileManager/types';
import { useToast } from '@app/panel/Composables/useToast';
import UploadedFile from '../../Containers/UploadedFile/components/UploadedFile.vue';
import { ArrowSmallRightIcon } from '@heroicons/vue/24/outline';
import { ErrorMessage } from '../../Components/ErrorMessage';
import { useLocale } from '@app/customer/Composables/useLocale';
import { getTranslationValue } from '@app/customer/Utils/translation';
import { ExclamationCircleIcon } from '@heroicons/vue/24/solid';
import { useI18n } from 'vue-i18n';
import { useApi } from '../../Composables/useApi';
import { router } from '@inertiajs/vue3';
import { MarkdownService } from '@app/shared/Services';
import { useSmartPolling } from '@app/customer/Composables/useSmartPolling';
import SearchInput from '../../../panel/Components/SearchInput/components/SearchInput.vue';
import { useFilter } from '@app/customer/Pages/DisplayOrder/Composables/useFilter';
import SkeletonLoading from '@app/customer/Pages/DisplayOrder/Components/SkeletonLoading.vue';

const { t } = useI18n();

type ReturnQuestionFieldType = App.Enums.ReturnQuestionFieldType;
type DisplayOrderViewModel = Types['App.Http.ViewModels.Customer.DisplayOrderViewModel'];
type SalesOrderViewModel = Types['App.Http.ViewModels.Customer.SalesOrderViewModel'];
type SalesOrderItemViewModel = Types['App.Http.ViewModels.Customer.SalesOrderItemViewModel'];

interface Answer {
  id: string;
  answer: string;
  translations: Record<string, string>;
}

const props = defineProps<DisplayOrderViewModel>();

const salesOrder = computed<SalesOrderViewModel | null>(() => props.salesOrder);

const { currentLocale } = useLocale();

const redirectData = computed<any>(() => usePage().props.redirectData);

const salesOrderItemWithInvalidQuantity = computed<SalesOrderItem>(() => {
  return salesOrder.value?.salesOrderItems.find((s) => s.id === redirectData.value?.invalidQuantitySalesOrderItemId);
});

const reasonOptions = computed((): SelectOption[] => {
  return props.reasons.map((reason: ReturnReason): SelectOption => {
    return {
      label: reason.label,
      value: reason.id,
    };
  });
});

const questions = ref<{
  order: any[];
  orderItems: any[];
}>({
  order: [],
  orderItems: [],
});

const isVirtualIntegration = computed<boolean>(() => props.platformIntegrationDriver === 'virtual-integration');

const previousReturnOrderAnswers = computed(() => props.requestData.order_data?.answers);

const form = useForm({
  item: getFormItems(),
  answers: isEmpty(previousReturnOrderAnswers.value) ? {} : previousReturnOrderAnswers.value,
  questions: questions,
});

function getFormItems(): [] {
  return salesOrder.value?.salesOrderItems.reduce((acc, salesOrderItem: SalesOrderItem) => {
    let previousAnswers = props.requestData.order_data?.item[salesOrderItem.id]?.answers;

    let storedIntentData = {
      quantity: props.requestData.order_data?.item[salesOrderItem.id]?.quantity ?? defaultSalesOrderItemQuantity(salesOrderItem),
      return_reason: props.requestData.order_data?.item[salesOrderItem.id]?.return_reason,
      answers: isEmpty(previousAnswers) ? {} : previousAnswers,
    };

    if (isVirtualIntegration.value) {
      storedIntentData['product_name'] = props.requestData.order_data?.item[salesOrderItem.id]?.product_name || '';
    }

    acc[salesOrderItem.id] = storedIntentData;

    return acc;
  }, {}) || [];
}

const inputComponents: Record<ReturnQuestionFieldType, any> = {
  text: TextInput,
  textarea: Textarea,
  file: FileUpload,
  dropdownSingle: Select,
  dropdownMulti: MultiSelect,
  radio: RadioButton,
  checkbox: Checkbox,
  ibanTextField: TextInput,
};

const debouncedQuestionEvaluation = debounce(() => {
  if (salesOrder.value !== null) {
    questions.value = evaluateReturnQuestions(form, props.questions, salesOrder.value, props.requestData);
    form.questions = questions;
  }
});

watch(() => [form.answers, form.item], () => {
  debouncedQuestionEvaluation();
}, { immediate: true, deep: true });

function pickFields(fields: ReturnQuestionFieldType[]) {
  return keys(pick(inputComponents, fields));
}

function onReturnQuantityChange(salesOrderItem: SalesOrderItem, value) {
  form.item[salesOrderItem.id].quantity = value;
}

function getOrderQuestionModelValue(questionId: string, fieldType: ReturnQuestionFieldType) {
  const multiple = pickFields(['dropdownMulti', 'checkbox']).includes(fieldType);
  const isAnswerPresent = form.answers[questionId];

  if (multiple && !isAnswerPresent) {
    return [];
  }

  return form.answers[questionId];
}

function getOrderItemQuestionModelValue(questionId: string, fieldType: ReturnQuestionFieldType, salesOrderItemId) {
  const multiple = pickFields(['dropdownMulti', 'checkbox']).includes(fieldType);
  const isAnswerPresent = form.item[salesOrderItemId].answers[questionId];

  if (multiple && !isAnswerPresent) {
    return [];
  }

  return form.item[salesOrderItemId].answers[questionId];
}

function updateOrderQuestionModelValue(questionId, value): void {
  form.answers[questionId] = value;
}

function updateOrderItemQuestionModelValue(questionId, salesOrderItemId, value): void {
  form.item[salesOrderItemId].answers[questionId] = value;
}

function getOptions(answers: Answer[]) {
  return answers.map((answer) => {
    return {
      label: getTranslationValue(
        answer.translations,
        currentLocale.value.id,
        answer.answer,
      ),
      value: answer.id,
    };
  });
}

function isRenderingMultipleComponents(fieldType: ReturnQuestionFieldType) {
  const multiple = pickFields(['radio', 'checkbox']);

  return multiple.includes(fieldType);
}

function getQuestionLabel(question) {
  if (question.isAnswerRequired) {
    return question.question + '*';
  }

  return question.question;
}

const {
  addFiles,
  error,
  removeFile,
  uploadedFiles,
} = useFileManager(5, props.attachmentFileSizeLimit, props.attachmentFileTypes);

function removeUploadedReturnOrderFile(questionId: string, fileKey: string) {
  const answers = Object.values(form.answers[questionId]).filter((file) => file.key !== fileKey).values();
  form.answers[questionId] = isEmpty(answers) ? {} : answers;
}

function removeUploadedReturnOrderItemFile(salesOrderId: string, questionId: string, fileKey: string) {
  const answers = Object.values(form.item[salesOrderId].answers[questionId]).filter((file) => file.key !== fileKey).values();
  form.item[salesOrderId].answers[questionId] = isEmpty(answers) ? {} : answers;
}

function constructOrderFileId(salesOrder: SalesOrder, question: ReturnQuestionAnswer): string {
  return salesOrder.id + '.' + question.id;
}

function constructOrderItemFileId(salesOrderItem: SalesOrderItem, question: ReturnQuestionAnswer): string {
  return salesOrderItem.id + '.' + question.id;
}

function getQuestionId(fileId: string): string {
  return fileId.split('.')[1];
}

function getSalesOrderItemId(fileId: string) {
  return fileId.split('.')[0];
}

function getAttributes(id: string, question) {
  const attributes = { id: question.id, label: getQuestionLabel(question) };
  const fieldType = question.fieldType as ReturnQuestionFieldType;

  if (fieldType === 'dropdownSingle' || fieldType === 'dropdownMulti') {
    attributes['placeholder'] = t('customer.order:select-option');
  }

  if (fieldType === 'file') {
    attributes['onFilesUpload'] = (files) => {
      addFiles(id, files);
    };

    attributes['allowedFileTypes'] = props.attachmentFileTypes;
    attributes['fileSizeLimit'] = props.attachmentFileSizeLimit;
    attributes['multiple'] = true;

    attributes['translations'] = {
      clickHere: t('customer.global:file-upload:click-here'),
      dragAndDrop: t('customer.global:file-upload:drag-and-drop'),
      upTo: t('customer.global:file-upload:up-to'),
    };
  }

  if (question.answers) {
    attributes['options'] = getOptions(question.answers);
  }

  return attributes;
}

function getAttributesForGroupField(question, answer: Answer) {
  return {
    id: question.id,
    label: getTranslationValue(
      answer.translations,
      currentLocale.value.id,
      answer.answer,
    ),
    value: answer.id,
  };
}

const { emitToastEvent } = useToast();

async function uploadFileToVapor(file: UploadedFileInterface) {
  const { upload } = useUpload();

  return await upload(file.file, (progress) => file.progress = progress)
    .then((response) => ({ filename: file.name, ...response }))
    .catch((error) => emitToastEvent({
      message: `Error while uploading ${file.name}: ${error}`,
    }));
}

async function uploadFilesToVapor(id: string, files: UploadedFileInterface[]) {
  const promises = [];

  files.forEach((file) => {
    promises.push(() => uploadFileToVapor(file));
  });

  return await Promise.all(promises.map((f) => f()))
    .then((response) => {
      return { id: id, fileData: { ...response } };
    });
}

function getAvailableFileIds(): string[] {
  if (salesOrder.value === null) {
    return [];
  }

  const availableFileIds: string[] = [];

  // Available file IDs for order.
  questions.value.order.forEach((question) => {
    if (question.fieldType !== App.Enums.ReturnQuestionFieldType.File) {
      return;
    }

    availableFileIds.push(`${salesOrder.value.id}.${question.id}`);
  });

  // Available file IDs for order items.
  forOwn(questions.value.orderItems, (questions, salesOrderItemId) => {
    questions.forEach((question) => {
      if (question.fieldType !== App.Enums.ReturnQuestionFieldType.File) {
        return;
      }

      availableFileIds.push(`${salesOrderItemId}.${question.id}`);
    });
  });

  return availableFileIds;
}

async function attachUploadedFilesToForm() {
  const availableFileIds = getAvailableFileIds();
  const promises = [];

  forOwn(uploadedFiles.value, async (files: UploadedFileInterface[], fileId: string) => {
    if (!availableFileIds.includes(fileId)) {
      return;
    }

    promises.push(() => uploadFilesToVapor(fileId, files));
  });

  await Promise.all(promises.map((f) => f()))
    .then((response) => {
      response.forEach((response) => {
        const question = props.questions.find((question) => {
          return question.id === getQuestionId(response.id);
        });

        if (question.level === 'order') {
          form.answers[question.id] = response.fileData;

          return;
        }

        const salesOrderItemId = getSalesOrderItemId(response.id);

        if (salesOrderItemId) {
          form.item[salesOrderItemId].answers[question.id] = response.fileData;
        }
      });
    });
}

async function onCreateReturn() {
  await attachUploadedFilesToForm();

  if (shouldShowFilter.value) {
    showSelected.value = true;
  }

  if (salesOrder.value === null) {
    return;
  }

  form.post(props.confirmRoute, {
    preserveScroll: true,
  });
}

function getOrderItemErrors(salesOrderItemId: string, questionId: string): string | null {
  return form.errors?.[`item.${salesOrderItemId}.answers.${questionId}`];
}

function getOrderErrors(questionId: string): string | null {
  return form.errors?.[`answers.${questionId}`];
}

const buttonColor = useBrandColor();

const answersBySalesItems = computed(() => {
  return Object.entries(form.item).reduce((acc, [key, salesItem]) => {
    acc[key] = Object.keys(salesItem.answers);
    return acc;
  }, {});
});

function canRemoveItem(salesOrderItem: SalesOrderItemViewModel): boolean {
  // There should always be at least one item
  if (salesOrder.value === null || salesOrder.value.salesOrderItems.length <= 1) {
    return false;
  }

  // A sales order items can only be removed when it is not linked to a return order item
  return salesOrderItem.hasReturnOrderItems === false;
}

function getOrderItemUploadedFiles(salesOrderItem, question) {
  return (uploadedFiles.value[constructOrderItemFileId(salesOrderItem, question)] || []);
}

function getOrderUploadedFiles(salesOrder, question) {
  return (uploadedFiles.value[constructOrderFileId(salesOrder, question)] || []);
}

// Remove sales item answer if it's not present in currently visible questions
watch(() => questions.value.orderItems, (newValue) => {
  Object.entries(answersBySalesItems.value)
    .forEach(([salesItemId, answers]) => {
      answers.forEach((questionId) => {
        const presentQuestions = map(newValue[salesItemId], 'id');

        if (!presentQuestions.includes(questionId)) {
          delete form.item[salesItemId].answers[questionId];
        }
      });
    });
});

// Remove order level answer if it's not present in currently visible questions
watch(() => questions.value.order, (newValue) => {
  const presentQuestions = newValue.map((question) => question.id);

  Object.keys(form.answers).forEach((questionId) => {
    if (!presentQuestions.includes(questionId)) {
      delete form.answers[questionId];
    }
  });
});

function addEmptyFormItem(id: string): void {
  form.item[id] = {
    quantity: 1,
    product_name: '',
    answers: {},
  };
}

function removeFormItem(id: string): void {
  delete form.item[id];
}

function reloadSalesOrderData(salesOrderItem, action: 'add' | 'remove' = 'add'): void {
  router.visit(route('customer.intent.display', { returnOrderIntent: props.returnOrderIntentId }), {
    only: ['salesOrder'],
    preserveScroll: true,
    preserveState: true,
    onStart: () => action === 'add' && addEmptyFormItem(salesOrderItem.id),
    onSuccess: () => action === 'remove' && removeFormItem(salesOrderItem.id),
    onError: () => handleGlobalError(),
  });
}

function addAdditionalProduct() {
  useApi().post(route('api.virtual-integration.sales-order-items.store', { sales_order_id: salesOrder.value.id }))
    .then((response) => reloadSalesOrderData(response.data.data))
    .catch(() => handleGlobalError());
}

function removeProduct(salesOrderItem) {
  useApi().post(route('api.virtual-integration.sales-order-items.destroy', {
    salesOrderItem: salesOrderItem.id,
    _method: 'delete',
  }))
    .then(() => reloadSalesOrderData(salesOrderItem, 'remove'))
    .catch(() => handleGlobalError());
}

function handleGlobalError() {
  emitToastEvent({
    message: t('customer.global:error-message'),
  });
}

const {
  phrase,
  showSelected,
  filter,
  filterSelected,
  salesOrderItems,
  shouldShowFilter,
  selectedSalesOrderItems,
} = useFilter(salesOrder, form, isVirtualIntegration);

const hasSalesOrder = computed(() => salesOrder.value !== null);

const { poll } = useSmartPolling();

if (!hasSalesOrder.value) {
  poll(
    route('api.intent.status', { returnOrderIntent: props.returnOrderIntentId }),
    (response) => {
      switch (response.data.status) {
        case App.Enums.ReturnOrderIntentStatus.COMPLETED:
          return handlePollingSuccess();
        case App.Enums.ReturnOrderIntentStatus.FAILED:
          return handlePollingFailure();
      }
    },
    () => {
      handlePollingFailure();
    },
  );
}

function handlePollingSuccess(): boolean {
  router.reload({
    onSuccess: () => {
      form.item = getFormItems();
    },
  });

  return true;
}

function handlePollingFailure(): boolean {
  router.get(route('customer.form.show'), {
    order_number: props.orderNumber,
    [props.validationMethod]: props.validationValue,
    status: App.Enums.ReturnOrderIntentStatus.FAILED,
  });

  return true;
}

function defaultSalesOrderItemQuantity(salesOrderItem): number {
  if (salesOrder.value === null || salesOrder.value.salesOrderItems.length > 1) {
    return 0;
  }

  // Only prefill when there is only 1, otherwise keep the default at 0
  return salesOrderItem.returnableQuantity === 1 ? 1 : 0;
}

const totalReturnQuantity = computed(() => {
  return Object.values(form.item).reduce((total, item) => {
    return total + item.quantity;
  }, 0);
});

const dynamicReturnCosts = computed(() => {
  const totalApplicableQuantity = totalReturnQuantity.value - (props.dynamicReturnCostsStartFrom - 1);

  return totalApplicableQuantity > 0
    ? totalApplicableQuantity * props.dynamicReturnCostsPerItem
    : 0;
});

provide('visibleProductDetails', props.visibleProductDetails);
</script>

<template>
  <AppLayout :previous-page-url="$route('customer.form.show')">
    <div class="max-w-3xl mx-auto">
      <div
        class="flex flex-col sm:flex-row sm:space-x-4 justify-between mb-6"
      >
        <Heading>
          <span
            v-if="salesOrder == null"
            class="mr-2 text-slate-500 font-normal"
          >
            {{ $t('customer.order:loading-order-title') }}
          </span>
          <span
            v-if="hasSalesOrder"
            class="mr-2 text-slate-500 font-normal"
          >
            {{ $t('customer.order:create-order-title') }}
          </span>
          <span class="whitespace-nowrap">{{ orderNumber }}</span>
        </Heading>

        <TextStyle
          v-if="! isVirtualIntegration && hasSalesOrder"
          color="slate"
          :shade="TextStyleShade.Subdued"
        >
          {{ $t('customer.order:date-title', { date: orderDate }) }}
        </TextStyle>
      </div>

      <div
        v-if="form.errors.quantity"
        class="mb-5"
      >
        <Banner :variant="BannerVariant.Critical">
          {{ form.errors.quantity }}
        </Banner>
      </div>

      <div
        v-if="salesOrderItemWithInvalidQuantity"
        class="mb-5"
      >
        <Banner :variant="BannerVariant.Critical">
          {{ $t('customer.order:invalid-quantity', { productName: salesOrderItemWithInvalidQuantity?.product.name }) }}
        </Banner>
      </div>

      <div
        v-if="form.errors.answers"
        class="mb-5"
      >
        <Banner :variant="BannerVariant.Critical">
          {{ form.errors.answers }}
        </Banner>
      </div>

      <div
        class="flex flex-col space-y-2"
      >
        <Banner
          v-for="previousIntent in previousIntents"
          :key="previousIntent.id"
          :variant="previousIntent.isFinalized ? BannerVariant.Success : BannerVariant.Info"
        >
          <div class="flex flex-col md:flex-row space-y-2 md:space-y-0 md:justify-between md:space-x-2 md:items-center">
            <div>
              <p v-if="previousIntent.isFinalized">
                {{
                  $t('customer.order:previous:finalized-message', {
                    return_number: previousIntent.returnNumber,
                    createdAt: previousIntent.createdAt,
                  })
                }}
              </p>
              <p v-else>
                {{ $t('customer.order:previous:resume-message') }}
              </p>
            </div>

            <div class="flex-shrink-0 ml-auto">
              <Link
                :href="previousIntent.previewUrl"
                no-underline
                native
              >
                <span class="flex items-center space-x-2">
                  <span
                    v-if="previousIntent.finalized"
                    class="text-xs text-nowrap"
                  >
                    {{ $t('customer.order:previous:finalized-action') }}
                  </span>
                  <span
                    v-else
                    class="text-xs text-nowrap"
                  >
                    {{ $t('customer.order:previous:resume-action') }}
                  </span>

                  <ArrowSmallRightIcon
                    class="h-4 w-4 stroke-2"
                  />
                </span>
              </Link>
            </div>
          </div>
        </Banner>
      </div>

      <div
        v-if="shouldShowFilter"
        class="flex flex-col space-y-4"
      >
        <div class="mt-4">
          <SearchInput
            v-model="phrase"
            :full-width="true"
            :placeholder="$t('customer.order:filter:input-placeholder')"
            @update:model-value="filter"
          />
        </div>
        <div
          v-if="selectedSalesOrderItems.length"
          class="grid grid-cols-2 select-none"
        >
          <div
            class="text-sm text-center py-3 cursor-pointer"
            :class="{ 'hover:border-b-2 border-slate-200 font-medium': !showSelected, 'text-slate-400': showSelected }"
            :style="showSelected ? '' : `border-bottom: 2px solid ${buttonColor.background};`"
            @click="filterSelected(false)"
          >
            {{ $t('customer.order:filter:view-all') }} ({{ salesOrder?.salesOrderItems.length }})
          </div>
          <div
            class="text-sm text-center pb-3 py-3 cursor-pointer "
            :class="{ 'hover:border-b-2 border-slate-200 font-medium': showSelected, 'text-slate-400': !showSelected }"
            :style="showSelected ? `border-bottom: 2px solid ${buttonColor.background};` : ''"
            @click="filterSelected(true)"
          >
            {{ $t('customer.order:filter:view-selected') }} ({{ selectedSalesOrderItems.length }})
          </div>
        </div>
      </div>

      <SkeletonLoading
        v-if="!hasSalesOrder"
      />

      <OrderGroup>
        <div
          v-if="salesOrderItems.length === 0"
          class="mt-8"
        >
          <Banner
            v-if="phrase !== ''"
            :variant="BannerVariant.Warning"
          >
            {{ $t('customer.order:filter:no-items-found') }}: <strong>{{ phrase }}</strong>
          </Banner>

          <Banner
            v-if="hasSalesOrder && phrase === ''"
            :variant="BannerVariant.Warning"
          >
            {{ $t('customer.order:no-items-found') }}
          </Banner>
        </div>

        <div
          v-for="salesOrderItem in salesOrderItems"
          :key="salesOrderItem.id"
        >
          <OrderItem
            v-if="form.item[salesOrderItem.id]"
            :key="salesOrderItem.product.id"
            :item="salesOrderItem"
            :quantity="salesOrderItem.quantity"
            :value="form.item[salesOrderItem.id].quantity ?? 0"
            :is-virtual-integration="isVirtualIntegration"
            :may-remove-item="canRemoveItem(salesOrderItem)"
            @increment="(value) => onReturnQuantityChange(salesOrderItem, value)"
            @decrement="(value) => onReturnQuantityChange(salesOrderItem, value)"
            @remove="removeProduct(salesOrderItem)"
          >
            <FormLayout>
              <FormLayout v-if="isVirtualIntegration && productNameEnabled">
                <TextInput
                  v-if="productNameEnabled"
                  v-model="form.item[salesOrderItem.id].product_name"
                  :label="$t('customer.order:product-name:label')"
                  :error="form.errors[`item.${salesOrderItem.id}.product_name`]"
                />
              </FormLayout>
              <FormLayout v-if="isVirtualIntegration && skuEnabled">
                <TextInput
                  v-model="form.item[salesOrderItem.id].sku"
                  :label="$t('customer.status:return-item:sku')"
                  :error="form.errors[`item.${salesOrderItem.id}.sku`]"
                />
              </FormLayout>
              <template v-if="form.item[salesOrderItem.id].quantity > 0">
                <FormLayout v-if="isVirtualIntegration && returnReasonEnabled">
                  <Select
                    v-model="form.item[salesOrderItem.id].return_reason"
                    :label="$t('customer.order:return-reason:label')"
                    :options="reasonOptions"
                    :error="form.errors[`item.${salesOrderItem.id}.return_reason`]"
                    :placeholder="$t('customer.order:select-option')"
                  />
                </FormLayout>
                <FormLayout v-if="!isVirtualIntegration">
                  <Select
                    v-model="form.item[salesOrderItem.id].return_reason"
                    :label="$t('customer.order:return-reason:label')"
                    :options="reasonOptions"
                    :error="form.errors[`item.${salesOrderItem.id}.return_reason`]"
                    :placeholder="$t('customer.order:select-option')"
                  />
                </FormLayout>

                <FormLayout>
                  <template
                    v-for="question in questions.orderItems[salesOrderItem.id]"
                    :key="question.id"
                  >
                    <component
                      :is="inputComponents[question.fieldType]"
                      v-if="!isRenderingMultipleComponents(question.fieldType)"
                      v-bind="getAttributes(
                        constructOrderItemFileId(salesOrderItem, question),
                        question,
                      )"
                      :model-value="getOrderItemQuestionModelValue(question.id, question.fieldType, salesOrderItem.id)"
                      :error="getOrderItemErrors(salesOrderItem.id, question.id) || error[question.id]"
                      @update:model-value="(value) =>
                        updateOrderItemQuestionModelValue(question.id, salesOrderItem.id, value)"
                    >
                      <template
                        v-if="question.description"
                        #help-text
                      >
                        <span v-html="MarkdownService.parse(question.description)" />
                      </template>
                    </component>

                    <template v-if="question.fieldType === 'file' && getOrderItemUploadedFiles(salesOrderItem, question)">
                      <UploadedFile
                        v-for="file in getOrderItemUploadedFiles(salesOrderItem, question)"
                        :key="file.id"
                        :name="file.name"
                        :progress="file.progress"
                        @remove="() => removeFile(
                          constructOrderItemFileId(salesOrderItem, question),
                          file.id
                        )"
                      />
                    </template>

                    <template v-if="question.fieldType === 'file' && Array.isArray(form.item[salesOrderItem.id].answers[question.id])">
                      <UploadedFile
                        v-for="file in form.item[salesOrderItem.id].answers[question.id]"
                        :key="file.key"
                        :name="file.filename"
                        :progress="100"
                        @remove="removeUploadedReturnOrderItemFile(salesOrderItem.id, question.id, file.key)"
                      />
                    </template>

                    <template v-if="isRenderingMultipleComponents(question.fieldType)">
                      <div class="space-y-2">
                        <div>
                          <InputLabel
                            :label="getQuestionLabel(question)"
                            :label-for="question.id"
                            flush
                          />

                          <HelpText v-if="question.description">
                            <span v-html="MarkdownService.parse(question.description)" />
                          </HelpText>
                        </div>

                        <ErrorMessage v-if="getOrderItemErrors(salesOrderItem.id, question.id)">
                          <div
                            class="flex items-center space-x-2"
                          >
                            <ExclamationCircleIcon class="h-5 w-5 text-red-400" />
                            <span>{{ getOrderItemErrors(salesOrderItem.id, question.id) }}</span>
                          </div>
                        </ErrorMessage>

                        <div class="space-y-1">
                          <template
                            v-for="answer in question.answers"
                            :key="answer.id"
                          >
                            <component
                              :is="inputComponents[question.fieldType]"
                              :error="form.errors?.[`item.${salesOrderItem.id}.answers.${question.id}`]"
                              v-bind="getAttributesForGroupField(questions, answer)"
                              :model-value="getOrderItemQuestionModelValue(question.id, question.fieldType, salesOrderItem.id)"
                              @update:model-value="(value) =>
                                updateOrderItemQuestionModelValue(question.id, salesOrderItem.id, value)"
                            />
                          </template>
                        </div>
                      </div>
                    </template>
                  </template>
                </FormLayout>
              </template>
            </FormLayout>
          </OrderItem>
        </div>
      </OrderGroup>

      <div
        v-if="isVirtualIntegration"
        class="mb-6 text-right"
      >
        <Button @click="addAdditionalProduct">
          {{ $t('customer.order:add-additional-product') }}
        </Button>
      </div>

      <hr
        v-if="dynamicReturnCosts"
        class="mb-3 border-slate-100"
      >

      <div
        v-if="dynamicReturnCosts"
        class="grid grid-cols-10 mx-auto space-y-2"
        :class="{'pt-4 pl-1.5': !isVirtualIntegration}"
      >
        <div
          class="col-span-10 pt-2 pb-8"
          :class="{'sm:col-start-4 sm:col-span-8': !isVirtualIntegration}"
        >
          <div class="flex items-center justify-between">
            <span class="text-slate-700 font-medium text-sm">
              {{ $t('customer.order:estimated-extra-shipping-costs', { items: totalReturnQuantity }, totalReturnQuantity) }}
            </span>

            <span class="text-slate-700 font-medium text-sm">
              {{ dynamicReturnCosts.toLocaleString('nl', { style: 'currency', currency: salesOrder.currency }) }}
            </span>
          </div>

          <div
            v-if="dynamicReturnCostsInstruction"
            class="mt-2"
          >
            <span
              class="text-xs text-slate-400"
              v-html="dynamicReturnCostsInstruction"
            />
          </div>
        </div>
      </div>

      <hr
        v-if="!isEmpty(questions.order)"
        class="mb-4 border-slate-100"
      >

      <div
        class="grid grid-cols-10 mx-auto"
        :class="{'pt-4 pl-1.5': !isVirtualIntegration}"
      >
        <div
          class="col-span-10"
          :class="{'sm:col-start-4 sm:col-span-8': !isVirtualIntegration}"
        >
          <FormLayout>
            <template
              v-for="question in questions.order"
              :key="question.id"
            >
              <component
                :is="inputComponents[question.fieldType]"
                v-if="!isRenderingMultipleComponents(question.fieldType)"
                :error="getOrderErrors(question.id) || error[question.id]"
                v-bind="getAttributes(
                  constructOrderFileId(salesOrder, question),
                  question,
                )"
                :model-value="getOrderQuestionModelValue(question.id, question.fieldType)"
                @update:model-value="(value) =>
                  updateOrderQuestionModelValue(question.id, value)"
              >
                <template
                  v-if="question.description"
                  #help-text
                >
                  <span v-html="MarkdownService.parse(question.description)" />
                </template>
              </component>

              <template v-if="question.fieldType === 'file' && getOrderUploadedFiles(salesOrder, question)">
                <UploadedFile
                  v-for="file in getOrderUploadedFiles(salesOrder, question)"
                  :key="file.id"
                  :name="file.name"
                  :progress="file.progress"
                  @remove="() => removeFile(
                    constructOrderFileId(salesOrder, question),
                    file.id
                  )"
                />
              </template>

              <template v-if="question.fieldType === 'file' && Array.isArray(form.answers[question.id])">
                <UploadedFile
                  v-for="file in form.answers[question.id]"
                  :key="file.key"
                  :name="file.filename"
                  :progress="100"
                  @remove="removeUploadedReturnOrderFile(question.id, file.key)"
                />
              </template>

              <template v-if="isRenderingMultipleComponents(question.fieldType)">
                <div class="space-y-2">
                  <div>
                    <InputLabel
                      :label="getQuestionLabel(question)"
                      :label-for="question.id"
                      flush
                    />

                    <HelpText v-if="question.description">
                      <span v-html="MarkdownService.parse(question.description)" />
                    </HelpText>
                  </div>

                  <ErrorMessage v-if="getOrderErrors(question.id)">
                    <div class="flex items-center space-x-2">
                      <ExclamationCircleIcon class="h-5 w-5 text-red-400" />
                      <span>{{ getOrderErrors(question.id) }}</span>
                    </div>
                  </ErrorMessage>

                  <div class="space-y-1">
                    <component
                      :is="inputComponents[question.fieldType]"
                      v-for="answer in question.answers"
                      :key="answer.id"
                      v-bind="getAttributesForGroupField(questions, answer)"
                      :model-value="getOrderQuestionModelValue(question.id, question.fieldType)"
                      @update:model-value="(value) => updateOrderQuestionModelValue(question.id, value)"
                    />
                  </div>
                </div>
              </template>
            </template>
          </FormLayout>
        </div>
      </div>

      <div
        :class="isEmpty(questions.order) ? 'mt-6' : 'mt-12'"
        class="ml-auto"
      >
        <ButtonGroup>
          <Button
            full-width
            :href="$route('customer.form.show')"
          >
            {{ $t('customer.order:back-button') }}
          </Button>

          <Button
            :color="buttonColor"
            full-width
            :loading="form.processing"
            :disabled="salesOrder === null || totalReturnQuantity === 0"
            @click=" onCreateReturn"
          >
            {{ $t('customer.order:continue-button') }}
          </Button>
        </ButtonGroup>
      </div>
    </div>
  </AppLayout>
</template>
