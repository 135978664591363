<script lang="ts" setup>
import {
  Breadcrumbs,
  BreadcrumbsItem,
  Heading,
  TextContainer,
  TextContainerSpacing,
  TextStyle,
  TextStyleFontSize,
  TextStyleShade,
  Toggle,
} from '@app/customer/Components';
import { NoteForm, Note } from '@app/customer/Containers';
import { OrderStatusAnswerList } from '@app/customer/Partials';
import { productDetailsService } from '@app/customer/Services';
import { type Types } from '@app/shared/types/generated-v2';
import { App } from '@app/shared/types/generated-v2';
import { ref } from 'vue';

type ProductDetailType = App.Enums.ProductDetailType;
type ReturnOrderItemViewModel = Types['App.Models.ViewModels.ReturnOrderItemViewModel'];
type ReturnOrderIntentViewModel = Types['App.Models.ViewModels.ReturnOrderIntentViewModel'];

const props = defineProps<{
  returnOrderIntent: ReturnOrderIntentViewModel;
  returnOrderItem: ReturnOrderItemViewModel;
  visibleProductDetails: ProductDetailType[];
}>();

const { isVisible } = productDetailsService(props.visibleProductDetails);

const notesVisible = ref(false);
</script>

<template>
  <article class="flex flex-col sm:flex-row">
    <div class="flex shrink-0 overflow-hidden bg-white h-40 w-40">
      <img
        v-if="returnOrderItem.product.imageSource"
        v-fallback-img
        :src="returnOrderItem.product.imageSource"
        :alt="returnOrderItem.product.name"
        class="h-full w-full object-contain"
        loading="lazy"
      >
    </div>
    <div class="mt-6 sm:mt-0 sm:ml-6 flex-1">
      <div class="space-y-4">
        <div class="flex justify-between space-x-6">
          <div class="space-y-1">
            <Heading>{{ returnOrderItem.salesOrderItem.productName ?? returnOrderItem.product.name }}</Heading>
            <TextContainer :spacing="TextContainerSpacing.Minimal">
              <Breadcrumbs>
                <BreadcrumbsItem
                  v-if="isVisible(App.Enums.ProductDetailType.SKU)"
                  :name="$t('customer.status:return-item:sku')"
                  :value="returnOrderItem.product.sku"
                />
                <BreadcrumbsItem
                  v-if="returnOrderItem.product.barcode && isVisible(App.Enums.ProductDetailType.BARCODE)"
                  :name="$t('customer.status:return-item:barcode')"
                  :value="returnOrderItem.product.barcode"
                />
                <BreadcrumbsItem
                  v-if="isVisible(App.Enums.ProductDetailType.PRODUCT_ID)"
                  :name="$t('customer.status:return-item:product-id')"
                  :value="returnOrderItem.product.externalId"
                />
              </Breadcrumbs>
            </TextContainer>
          </div>
          <div class="flex flex-col items-end text-right whitespace-nowrap">
            <TextStyle
              :font-size="TextStyleFontSize.Small"
              :shade="TextStyleShade.Pale"
            >
              {{ $t('customer.status:return-item:return-qty') }}
            </TextStyle>
            <TextStyle
              :font-size="TextStyleFontSize.Huge"
              bold
            >
              {{ returnOrderItem.quantity }} / {{ returnOrderItem.salesOrderItem.quantity }}
            </TextStyle>
          </div>
        </div>
        <div class="flex flex-col space-y-2 break-all">
          <div class="flex flex-col">
            <TextStyle
              :shade="TextStyleShade.Dark"
              strong
            >
              {{ $t('customer.status:return-item:return-reason') }}
            </TextStyle>
            <TextStyle :shade="TextStyleShade.Subdued">
              {{ returnOrderItem.returnReason.label }}
            </TextStyle>
          </div>
          <OrderStatusAnswerList :answers="returnOrderItem.answers" />
        </div>
        <section v-if="returnOrderIntent.form.areCustomerNotesAllowed || returnOrderItem.notes.length > 0">
          <div class="flex items-center">
            <label class="flex items-center cursor-pointer">
              <Toggle v-model="notesVisible" />
              <TextStyle :shade="TextStyleShade.Dark">
                <span>{{ $t('customer.status:notes:show_notes') }}</span>

                <TextStyle :shade="TextStyleShade.Subdued">
                  ({{ returnOrderItem.notes?.length ?? 0 }})
                </TextStyle>
              </TextStyle>
            </label>
          </div>
          <div
            v-if="notesVisible"
            class="flex flex-col space-y-3 mt-3"
          >
            <div
              v-if="returnOrderItem.notes.length > 0"
              class="flex flex-col space-y-4 mt-4"
            >
              <Note
                v-for="note in returnOrderItem.notes"
                :key="note.id"
                v-bind="note"
              />
            </div>
            <NoteForm
              v-if="returnOrderIntent.form.areCustomerNotesAllowed"
              :return-order-item="returnOrderItem"
            />
          </div>
        </section>
      </div>
    </div>
  </article>
</template>
