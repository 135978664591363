<script lang="ts" setup>
import { PropType, computed, ref } from 'vue';
import { DropZone } from './index';
import { DocumentPlusIcon } from '@heroicons/vue/24/outline';
import { ErrorMessage } from '../../ErrorMessage';
import { HelpText } from '../../HelpText';
import InputLabel from '../../InputLabel/components/InputLabel.vue';

interface FileUploadTranslation {
  clickHere: string;
  dragAndDrop: string;
  upTo: string;
}

const props = defineProps({
  label: {
    type: String,
    default: null,
  },

  error: {
    type: [String, null],
    default: null,
  },

  onFilesUpload: {
    type: Function as PropType<{ (files: File[]) }>,
    required: true,
  },

  multiple: {
    type: Boolean,
    default: false,
  },

  allowedFileTypes: {
    type: Array as PropType<string[]>,
    default: () => [],
  },

  fileSizeLimit: {
    type: Number,
    default: null,
  },

  translations: {
    type: [Object, null] as PropType<FileUploadTranslation | null>,
    required: false,
    default: () => ({
      clickHere: 'Click here',
      dragAndDrop: 'or drag and drop',
      upTo: 'up to',
    }),
  },
});

const formattedFileTypes = computed<string>(() => {
  return props.allowedFileTypes.map((fileType) => {
    return fileType.split('/').pop().toUpperCase();
  }).join(', ');
});

const accept = computed<string>(() => {
  return props.allowedFileTypes.join(',');
});

const formattedFileSizeLimit = computed<number>(() => {
  return props.fileSizeLimit / 1024 / 1024;
});

function onInputChange(e: InputEvent): void {
  const target = e.target as HTMLInputElement;

  props.onFilesUpload([...target.files]);

  target.files = null;
  (e.target as HTMLInputElement).value = null;
}

const fileInput = ref(null);

function openFileUploadBrowser(): void {
  fileInput.value.click();
}
</script>

<template>
  <div>
    <InputLabel
      v-if="label"
      :label="label"
      :flush="!! $slots['help-text']"
    />

    <HelpText v-if="!! $slots['help-text']">
      <slot name="help-text" />
    </HelpText>

    <DropZone
      v-slot="{ dropZoneActive }"
      @files-dropped="onFilesUpload"
    >
      <div
        class="mt-1 flex flex-col space-y-4 px-6 pt-5 pb-6 border border-dashed rounded-md cursor-pointer"
        :class="dropZoneActive ? 'border-slate-400 bg-slate-50' : error ? 'border-red-300' : 'border-slate-300'"
        @click="openFileUploadBrowser"
      >
        <div class="flex flex-col items-center space-y-1 text-center">
          <DocumentPlusIcon class="h-12 w-12 text-slate-400 mx-auto" />

          <div class="flex text-sm text-slate-600">
            <div
              class="relative cursor-pointer bg-white rounded-md font-medium"
              @click.stop="openFileUploadBrowser"
            >
              <div>
                <span class="text-blue-600 hover:underline">
                  {{ translations.clickHere }}
                </span>

                <span class="font-normal">&nbsp;{{ translations.dragAndDrop }}</span>
              </div>

              <div v-if="allowedFileTypes && fileSizeLimit">
                <span class="text-xs text-slate-300 font-normal">{{ formattedFileTypes }} {{ translations.upTo }} {{ formattedFileSizeLimit }}MB</span>
              </div>

              <input
                ref="fileInput"
                name="file-upload"
                type="file"
                class="sr-only"
                :accept="accept"
                :multiple="multiple"
                @change="onInputChange"
              >
            </div>
          </div>
        </div>
      </div>

      <ErrorMessage
        v-if="error"
        :message="error"
      />
    </DropZone>
  </div>
</template>
